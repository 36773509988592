import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import { Example } from './Example';
import FAQPlaceholder from './FAQPlaceholder';
const FAQ = () => {
  const router = useRouter();
  const accordionIds = [
    {
      title: (
        <section className='tw-text-largeText tw-font-fontMedium tw-text-darkerGrey'>
          Can I Connect Multiple HubSpot accounts with FormPay?
        </section>
      ),
      id: 'resources',
      content: <></>,
      description:
        'Yes, you can. If you use different HubSpot accounts for payment-related activities, you can connect them with FormPay. Here are the steps to do it',
      url: 'https://support.makewebbetter.com/hubspot-apps/can-i-connect-multiple-hubspot-accounts-with-formpay/',
    },
    {
      title: (
        <section className='tw-text-largeText tw-font-fontMedium tw-text-darkerGrey'>
          How can I see my billing history with the help of an app?
        </section>
      ),
      id: 'company',
      content: <></>,
      description: (
        <section>
          <section className='tw-py-2 tw-text-start tw-text-normalText tw-text-lightBlack'>
            {' '}
            The billing history allows you to check all the payments you made
            for the FormPay app in order to renew your subscription.
          </section>
          <section className='tw-py-2 tw-text-start tw-text-normalText tw-text-lightBlack'>
            To see your billing history, you can:
          </section>

          <section className='tw-py-2 tw-text-start tw-text-normalText tw-text-lightBlack'>
            Login to the FormPay app.
          </section>
          <section className='tw-py-2 tw-text-start tw-text-normalText tw-text-lightBlack'>
            Navigate to User Accounts from the left navigation menu.
          </section>
          <section className='tw-py-2 tw-text-start tw-text-normalText tw-text-lightBlack'>
            Click on the Billing tab on that page and you’ll see your billing
            history there.
          </section>
        </section>
      ),
      url: 'https://support.makewebbetter.com/hubspot-apps/how-can-i-see-my-billing-history/',
    },
    {
      title: (
        <section className='tw-text-largeText tw-font-fontMedium tw-text-darkerGrey'>
          How can I track data of HubSpot form submissions using app ?
        </section>
      ),
      id: 'company-2',
      content: <></>,
      description: (
        <section>
          <section className='tw-py-2 tw-text-start tw-text-normalText tw-text-lightBlack'>
            {' '}
            Follow these steps to track the data of HubSpot forms submissions:
          </section>

          <section className='tw-py-2 tw-text-start tw-text-normalText tw-text-lightBlack'>
            Login to the FormPay app.
          </section>
          <section className='tw-py-2 tw-text-start tw-text-normalText tw-text-lightBlack'>
            Navigate to Form Submission from the left sidebar.
          </section>
          <section className='tw-py-2 tw-text-start tw-text-normalText tw-text-lightBlack'>
            There, you’ll see the data of all the HubSpot forms submitted on
            your website.
          </section>
        </section>
      ),
      url: 'https://support.makewebbetter.com/hubspot-apps/track-data-of-hubspot-form-submissions/',
    },
  ];
  accordionIds.map(item => {
    item.content = (
      <FAQPlaceholder description={item?.description} url={item?.url} />
    );
  });

  return (
    <section className='t tw-mx-[11px] tw-my-32 tw-flex tw-max-w-[1024px] tw-flex-col  tw-px-3   sm:tw-px-6 lg:tw-mt-[11px] lg:tw-max-w-[1200px] lg:tw-text-center  xl:tw-mx-auto'>
      <section className='tw-flex tw-flex-col tw-justify-between md:tw-flex-row'>
        <section
          tabIndex={0}
          className='tw-lg:[36px] tw-my-2 tw-flex tw-justify-start tw-text-[24px] tw-font-fontBold tw-text-darkerGrey md:tw-justify-center lg:tw-justify-start'
        >
          Frequently Asked Questions
        </section>
        <Link
          href='https://support.makewebbetter.com/hubspot-apps/category/hubspot-formpay-integration/'
          target='_blank'
        >
          <button
            tabIndex={0}
            className='tw-my-2 tw-h-11 tw-w-48 tw-rounded-[8px] tw-border tw-border-solid tw-border-purpleLight2 tw-bg-purpleLight2 tw-text-formPay100 tw-shadow-[0_1px_2px_rgba(16,24,40,0.05)]'
          >
            View all Articles
          </button>
        </Link>
      </section>

      <section className=' tw-bg-white tw-py-6'>
        <section className='tw-grid tw-gap-y-8'>
          <Example
            accordionIds={accordionIds}
            isDivider={true}
            dynamicIcons={{
              open: `${router.basePath}/assets/images/Accordion.webp`,
              close: `${router.basePath}/assets/images/AccordionHide.webp`,
            }}
          />
        </section>
      </section>
      <section className={`tw-my-8 tw-grid tw-grid-cols-12`}>
        <section className='tw-col-span-12 tw-my-2 tw-flex tw-flex-col tw-justify-between tw-rounded-[16px] tw-border-[1px] tw-border-normalBlack tw-bg-[#f9fafb] tw-p-8 sm:tw-mt-6 md:tw-col-span-6 lg:tw-col-span-6 lg:tw-my-0 lg:tw-mr-10'>
          <section
            className='tw-flex tw-justify-start tw-text-[20px] tw-font-fontMedium tw-text-darkerGrey'
            tabIndex={0}
          >
            Didn’t find your answer?
          </section>
          <section
            className='tw-flex tw-justify-start tw-text-left tw-text-largeText tw-font-fontLight tw-text-lightBlack'
            tabIndex={0}
          >
            Can’t find the answer you’re looking for? Please chat to our
            friendly team.
          </section>
          <section tabIndex={0} className='tw-mt-5 tw-flex tw-justify-start'>
            <Link
              href='https://support.makewebbetter.com/hubspot-apps/category/hubspot-formpay-integration/'
              target='_blank'
            >
              <button className='tw-flex tw-w-auto tw-justify-center tw-bg-white tw-px-4 tw-py-2 tw-text-smallText tw-font-fontMedium tw-text-darkBlack hover:tw-bg-gray-100'>
                Explore our Knowledge Base{' '}
                <span
                  className='tw-ml-2 tw-text-white'
                  role='img'
                  aria-label='explore knowledge base'
                >
                  <Image
                    alt='explore knowledge base'
                    src={`${router.basePath}/assets/images/BlackArrow.webp`}
                    width={20}
                    height={20}
                  />
                </span>
              </button>
            </Link>
          </section>
        </section>
        <section className='tw tw-col-span-12 tw-my-2 tw-flex tw-flex-col tw-justify-between tw-rounded-[16px] tw-border-[1px] tw-border-normalBlack tw-bg-[#f9fafb] tw-p-8 sm:tw-mt-6 md:tw-col-span-6 md:tw-mx-3 lg:tw-col-span-6 lg:tw-my-0'>
          <section
            tabIndex={0}
            className='tw-flex tw-justify-start tw-text-[20px] tw-font-fontMedium tw-text-darkerGrey'
          >
            Have a more complex query?
          </section>
          <section
            tabIndex={0}
            className='tw-flex tw-justify-start tw-text-left tw-text-largeText tw-font-fontLight tw-text-lightBlack'
          >
            Need an Expert Hand for Your Potential Project?
          </section>
          <section tabIndex={0} className='tw-mt-5 tw-flex tw-justify-start'>
            <Link href='https://makewebbetter.com/contact-us/' target='_blank'>
              <button className='tw-flex tw-w-auto tw-justify-center tw-bg-white tw-px-4 tw-py-2 tw-text-smallText tw-font-fontMedium tw-text-darkBlack hover:tw-bg-gray-100'>
                Ask our experts{' '}
                <span
                  className='tw-ml-2 tw-text-white'
                  role='img'
                  aria-label='ask experts'
                >
                  <Image
                    src={`${router.basePath}/assets/images/BlackArrow.webp`}
                    alt='ask experts'
                    width={20}
                    height={20}
                  />
                </span>
              </button>
            </Link>
          </section>
        </section>
      </section>
    </section>
  );
};
export default FAQ;
