import { motion } from 'framer-motion';
import Link from 'next/link';
import type { ReactNode } from 'react';
import React from 'react';
interface FAQPlaceholderInterface {
  description: string | ReactNode;
  url: string;
}
const FAQPlaceholder = (props: FAQPlaceholderInterface) => {
  const { description, url } = { ...props };

  return (
    <motion.section
      variants={{ collapsed: { scale: 0.8 }, open: { scale: 1 } }}
      tw-transition={{ duration: 0.2 }}
    >
      <section className='tw-p-4 tw-text-start tw-text-normalText tw-text-lightBlack'>
        {description}
        <Link href={url} target='_blank'>
          <p className='tw-text-formPay100'>More Details</p>
        </Link>
      </section>
    </motion.section>
  );
};
export default FAQPlaceholder;
