import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import FormattedText from './FormattedText';
const Gateways = () => {
  const router = useRouter();
  const gatewayItems = [
    {
      icon: `${router.basePath}/assets/images/gateway.webp`,
      title: 'Amazon Pay',
      description: 'Used by 5 million+ merchants globally',
    },
    {
      icon: `${router.basePath}/assets/images/gateway-2.webp`,
      title: 'Adyen',
      description: 'Local and cross-border transactions',
    },
    {
      icon: `${router.basePath}/assets/images/Paypal.webp`,
      title: 'PayPal',
      description: 'Supports all debit and credit cards',
    },
    {
      icon: `${router.basePath}/assets/images/applePay.webp`,
      title: 'Apple Pay',
      description: 'All safari and iPhone versions supported',
    },
    {
      icon: `${router.basePath}/assets/images/gatewayIcon.webp`,
      title: 'Stripe',
      description: 'Enables subscription and e-wallet support',
    },
    {
      icon: `${router.basePath}/assets/images/Razorpay.webp`,
      title: 'Razorpay',
      description: 'Best suited for Indian merchants',
    },
    {
      icon: `${router.basePath}/assets/images/googlePay.webp`,
      title: 'Google Pay',
      description: 'Faster and simpler checkouts',
    },
    {
      icon: `${router.basePath}/assets/images/authorizedNet.svg`,
      title: 'Authorize.Net',
      description: 'Perfect for small businesses',
    },
    {
      icon: `${router.basePath}/assets/images/payu-icon.svg`,
      title: 'PayU',
      description: 'Used by more than 500K merchants',
    },
    {
      icon: `${router.basePath}/assets/images/Klarna.svg`,
      title: 'Klarna',
      description: 'Faster and simpler checkouts',
    },
    {
      icon: `${router.basePath}/assets/images/AddGateway.webp`,
      title: 'Or any other gateway you request.',
      description: (
        <Link href='https://makewebbetter.com/contact-us/' target='_blank'>
          <button
            tabIndex={0}
            className='tw-flex tw-w-auto tw-items-center tw-justify-center tw-bg-white tw-px-4 tw-py-2 tw-text-smallText tw-font-fontMedium tw-text-formPay100'
          >
            Request your gateway{' '}
            <span
              className='tw-ml-2 tw-text-white'
              role='img'
              aria-label='try for free'
            >
              <Image
                src={`${router.basePath}/assets/images/arrow-right-purple.svg`}
                alt='Try for free'
                width={20}
                height={20}
              />
            </span>
          </button>
        </Link>
      ),
    },
  ];

  return (
    <section className='tw-my-20 tw-px-6'>
      <section
        tabIndex={0}
        className='tw-my-4 tw-text-center tw-text-normalText tw-font-fontBold tw-leading-[24px] tw-text-[#7936f4]'
      >
        Gateways
      </section>
      <section
        tabIndex={0}
        className='tw-my-4 tw-text-center tw-text-[24px] tw-leading-[32px] tw-text-darkerGrey md:tw-text-[28px] md:tw-font-fontBold md:tw-leading-[34px] lg:tw-text-veryLargeText lg:tw-leading-[44px]'
      >
        Connect and collect with your favourite gateways
      </section>

      <section className={`tw-mt-12 tw-grid tw-grid-cols-12`}>
        {gatewayItems.map((item, i) => (
          <section
            className={`tw-col-span-12 tw-m-8 tw-flex tw-justify-center md:tw-col-span-4 md:tw-m-3 lg:tw-col-span-3`}
            key={i}
          >
            <section className='tw-flex tw-h-[214px] tw-w-[360px] tw-flex-col tw-rounded-[16px] tw-bg-[#F9FAFB] tw-p-6 md:tw-w-[305px]'>
              <section
                tabIndex={0}
                className='tw-mx-auto tw-my-0'
                role='img'
                aria-label='gateway'
              >
                {' '}
                <Image
                  alt='amazon pay'
                  src={item?.icon}
                  width={56}
                  height={56}
                />
              </section>
              <FormattedText childClassNames='tw-mx-auto tw-my-2 tw-text-[18px] tw-font-fontBold tw-leading-[28px] tw-text-[#1C2433] lg:tw-text-[20px] lg:tw-leading-[30px] '>
                {item?.title}
              </FormattedText>
              <FormattedText childClassNames='	 tw-mx-auto tw-my-2 tw-w-[200px] tw-text-[16px] tw-leading-[22px] tw-text-[#677289]  lg:tw-text-[18px] lg:tw-leading-[28px]'>
                {item?.description}
              </FormattedText>
            </section>
          </section>
        ))}
      </section>
    </section>
  );
};
export default Gateways;
